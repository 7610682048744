export const ICON_SPRITE_URL = window.alex.ICON_SPRITE_URL || '/icons/icons.svg';
export const LANG = document.documentElement.lang || 'de';
export const BREAKPOINTS = {
  xs: '0px',
  s: '480px',
  m: '768px',
  l: '1024px',
  xl: '1280px',
  xxl: '1440px',
};
